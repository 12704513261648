export default function Logo(props: {className?: string}) {
    const foregroundColor = '#a51618';
    const backgroundColor = '#7f1618';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 5242.25 2852.25"
            className={props.className}
        >
            <g id="Layer_29" data-name="Layer 29">
                <path
                    fill={backgroundColor}
                    d="M3645,1505.89c-8.38,22.45-10.49,38.59-15.73,60.4q-24.58,102.3-49.68,204.46-23.09,94-46.6,187.88-56.93,227.32-116.38,454-59.15,225.57-120.78,450.47T3170.15,3312.2q-64.09,224.18-130.66,447.64-8.43,28.34-16.92,56.67c-10.85,36.27-9.22,76.31-5.52,101.4,11.26,76.31,86.71,125.71,123.95,150.09,43.79,28.67,158.89,104,265.55,57.21,77.83-34.15,115.82-117.86,120.89-129.38,13.29-30.22,22.29-62.08,30.69-93.93,8.46-32.12,19.3-64,28.73-96q29.68-100.54,58.88-201.24,13.43-46.32,26.75-92.67,64.73-225.2,127-451.1,61.92-224.82,121.38-450.31T4037.27,2159q34.28-136.08,67.67-272.41c21.53-87.9,50.19-177.64,59.91-267.79,1.62-15,1.74-19.55,1.8-21.76,3-106.07-75.77-220.24-170.65-238-7-1.3-22.73-.9-54,0-122.62,3.52-183.93,5.28-216,27C3677.59,1418.79,3656.13,1476.21,3645,1505.89Z"
                    transform="translate(-68.99 -1301.72)"
                />
                <path
                    fill={backgroundColor}
                    d="M2408,1796.08q-61.74,191.19-123.42,382.38-49.28,152.73-98.47,305.48-39.09,121.38-78.1,242.81-30.72,95.64-61.34,191.31-24.67,77.13-49.23,154.3-20.78,65.26-41.41,130.57-19,60-37.86,120.11-19.34,61.46-38.59,122.93-21.81,69.51-43.6,139-26.55,84.63-53.14,169.24c-24.86,79.08-30.59,171.88,8.2,247.59,38.39,74.93,121.62,126.34,204.1,136,81,9.53,167.71-19.4,222.5-80.7,72.4-81,91.78-191,123.28-291.37q22.76-72.51,45.48-145,19.8-63.11,39.6-126.21,18.79-59.79,37.65-119.57,20-63.43,40.15-126.82,23.41-73.7,47-147.34,28.95-90.59,58-181.13,36.63-114.12,73.36-228.2,46.44-144.28,93-288.55,58.7-182,117.45-364,16.2-50.18,32.41-100.36,16.76-53.44,4.79-106.89c-4.52-99.68-88.19-188.81-180.6-218.32C2607.8,1568.24,2448.14,1653.07,2408,1796.08Z"
                    transform="translate(-68.99 -1301.72)"
                />
                <path fill={backgroundColor} d="M3546,4104" transform="translate(-68.99 -1301.72)"/>
            </g>
            <g id="Layer_10" data-name="Layer 10">
                <path
                    fill={backgroundColor}
                    d="M321.16,3057H570.94c93.78,0,187.55.52,281.32.43,83-.07,169.85,5.74,243.32-40,34.19-21.29,63.14-50.46,82.16-86,19.67-36.71,32.86-76.46,44.17-116.43q37.42-132.27,73-265.28,13.93-52.41,28-104.79,22.24-82.26,44.75-164.44,40.46-148.76,81-297.47c18.66-68.4,38.44-136.6,55-205.52,15.89-66.3,45.73-130.22,48.47-199.19,3.4-85.27-39.63-171-110.05-219.21s-165.89-57.35-244.17-23.36c-75.12,32.63-122.72,100.48-146.31,176.63C1021,1611.14,996.18,1711.6,969,1811.28s-54.35,199.35-81.47,299Q848.93,2252.16,810.44,2394c-19,70.19-37.44,140.59-57.25,210.57l-36.13,127.69-.92,3.27L961,2549.13c-184.28,0-368.55-1-552.83.2-86.87.56-201.53-2.91-263.92,72-89.53,87.33-100.81,234.87-25.11,335.12C166.44,3019,242.85,3056.77,321.16,3057Z"
                    transform="translate(-68.99 -1301.72)"
                />
            </g>
            <g id="Layer_28" data-name="Layer 28">
                <polygon fill={foregroundColor} points="4546.47 1908.22 5242.25 1506.45 4546.47 1104.68 4546.47 1908.22"/>
                <path
                    fill={foregroundColor}
                    d="M4267,3062.06c19,1.62,38.12,2.12,57.05,2,83.66-.56,167.05-2,250.71-2h87.94q50.51,1.9,94.23-21.07a219.94,219.94,0,0,0,129.28-129.28q23-43.72,21.07-94.23l-8.74-65a244.42,244.42,0,0,0-62.9-107.93l-49.5-38.24a243.34,243.34,0,0,0-123.44-33.39H4354.93c-35.73,0-72.35-2.93-107.88.7a262.06,262.06,0,0,0-97.51,29.49c-41.56,22.38-76.65,57.12-98,99.23-16.72,33-34.2,77.36-29.12,115.16,10.22,76.06,36.51,153.51,102.59,199a221.81,221.81,0,0,0,19.93,12.21C4182.7,3049.15,4224.55,3058.43,4267,3062.06Z"
                    transform="translate(-68.99 -1301.72)"
                />
                <path
                    fill={foregroundColor}
                    d="M3644.34,1644.85q42.84,151.13,86.2,302.1,41,142.52,82.54,284.84,39.95,136.65,80.61,273.09c23.54,79,38.72,160.13,62.06,239.15,22.48,76.13,53.15,152.94,104.67,214.37,51,60.75,119.54,102.35,200.67,101.9a264.82,264.82,0,0,0,71.54-10.44,302.67,302.67,0,0,0,83.28-38.9c82.26-54.61,125.84-159.19,111.37-256.86-8.71-58.85-33.32-116.31-50.9-173-19.22-61.94-37.49-124.18-56.06-186.32q-40.65-136-80.55-272.25-41.31-141-81.93-282.12c-20.54-71.34-42-142.41-61.14-214.13-21.77-81.42-40.31-168.61-100.36-231.63-53.14-55.76-130.27-89.66-207.72-85.67-155.76,8-276.28,159.88-249.31,313.17Q3641.34,1633.63,3644.34,1644.85Z"
                    transform="translate(-68.99 -1301.72)"
                />
                <path
                    fill={foregroundColor}
                    d="M1066.2,1639.19c6,26.36,14.19,52.43,21.87,78.77q11.68,40.07,23.56,80.09,33.34,112.76,67.29,225.33,41.11,136.74,82.59,273.37,45.85,151.22,92,302.35,47.6,156.15,95.36,312.26,46.54,152.13,93.2,304.23c40.63,132.48,81.06,264.9,122.5,397.1q24.42,77.91,47.82,156.12c15.27,51.07,27.26,103.09,44.21,153.68,13.54,40.43,29.34,83.69,54.67,118.23,42.82,58.38,108.52,102.84,181.31,111.43,61.83,7.29,127.42-7.46,179.91-41,65.37-41.78,110-113.45,120.19-190.19,5.84-43.78-3-88.08-15-130.1-61.77-217.27-136.91-430.68-197.34-648.22q-45.71-148.95-91.31-297.93-47.91-156.48-95.68-313-46.75-153.21-93.31-306.48-42.82-141-85.39-282.11-36-119.26-71.52-238.66-26.16-88-51.71-176.14c-18.45-64.21-43.72-121.56-95.94-166-31.16-26.51-68.81-45.62-108.85-54.21-97.33-20.89-201.14,9.85-266.43,86.75-35.24,41.51-56.51,94.18-61.92,148.24C1054.93,1576.14,1059.05,1607.87,1066.2,1639.19Z"
                    transform="translate(-68.99 -1301.72)"
                />
                <path
                    fill={foregroundColor}
                    d="M2853.62,1684.14c-41-71.12-167.63-104.87-270.38-70.16-84.51,28.55-151.36,104-169.86,191.26-18.15,85.58,7.71,173.67,33.31,257.32,164.64,537.9,332.54,1074.93,494,1613.8,35.77,119.4,64.06,284.88,145.12,382.07,73.91,88.62,210.74,118.81,314.79,70.44,78.6-36.54,136.22-114.88,147.71-200.8,16.5-123.49-48.94-256.45-83.2-372.77-42-142.67-86.39-284.7-129.8-427l-89.49-293.2q-44.85-146.91-89.7-293.81-42-137.62-84-275.26-35.31-115.59-70.63-231.18-25.26-82.61-50.55-165.22c-14.8-48.33-25.58-99.09-51.37-143.15-67.84-115.94-218.38-157.8-339.88-105.78-137.62,58.92-192.84,228.72-129.09,361.53a245.48,245.48,0,0,0,28,45.24c69.43,88.59,219.14,131.85,329.05,74.53,125.45-65.42,152.58-238.15,139.11-245.64-5.65-3.13-16.73,26.48-46.53,49l-282.08,212.76C2826.62,1978.36,2909.2,1780.49,2853.62,1684.14Z"
                    transform="translate(-68.99 -1301.72)"
                />
                <path
                    fill={foregroundColor}
                    d="M3639,1613.25c3.29,15.8,5.95,28.61,5.95,28.61s-2.66-12.81-5.95-28.61l-5.94-28.61Z"
                    transform="translate(-68.99 -1301.72)"
                />
            </g>
        </svg>
    )
}
